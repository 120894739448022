
























import { Component, Prop, Vue } from "vue-property-decorator";
import { CRadio, CRadioStatus } from "@/models/utils/c-radio.interface";

@Component
export default class CosmopolRadio extends Vue {
  @Prop({
    required: false,
    default: () => ({
      time: "--:--",
      status: CRadioStatus.NONE,
    }),
    type: Object,
  })
  private radio: CRadio;

  get time(): string {
    let time = "--:--";
    if (this.radio.time && this.radio.time != "") {
      time = this.radio.time;
    }
    return time;
  }

  get src(): string {
    switch (this.radio.status) {
      case CRadioStatus.CHECK:
        return "check";
      case CRadioStatus.ERROR:
        return "error";
      default:
        return "none";
    }
  }
}
